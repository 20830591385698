import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Box, Heading, Themed } from "theme-ui"

const ProjectListItem = ( props ) => {

  const image = props.image ? props.image : null

  return (
    <Box
      as={`article`}
      sx={{
        my: [3, 3, 3, 3, 0, 0],
        h3: {
          my: 3,
          a: {
            border: 0,
            color: `text`,
            "&:hover": {
              border: 0,
            }
          }
        },
        p: {
          fontSize: 0,
          margin: 0,
        },
      }}
    >
      <Box as={`div`} className='bg-image'>
        <Link to={props.linktoproject} rel="bookmark">
          <GatsbyImage
            image={image}
            alt=""
          />
        </Link>
      </Box>
      <Heading as={`h3`}>
        <Themed.a as={Link} to={props.linktoproject} rel="bookmark">{props.title}</Themed.a>
      </Heading>
      <Themed.p>
        {props.date}
      </Themed.p>
    </Box>
  )

}

export default ProjectListItem
